@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}


.w-40 {
  width: 10rem;
}
.go-to-top-icon {
  position: fixed;
  bottom: 70px;
  right: 20px;
  color: rgb(255, 161, 71);
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.go-to-top-icon:hover {
  color: rgb(255, 161, 71);
  transform: scale(1.2);
}

.go-to-top-icon:active {
  transform: scale(1.1);
}