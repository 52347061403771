/* NavBar styles */
.navbar-wrapper {
    position: fixed; /* Fix the navbar on top */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    z-index: 1000; /* High z-index to ensure visibility */
    background-color: white; /* Optional: Set a background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  }
  
  /* Ensure there's space for the fixed navbar */
  .healthcare-container {
    padding-top: 70px; /* Adjust based on the navbar height */
  }
  